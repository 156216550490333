<template>
  <v-container fluid>
    <v-progress-linear indeterminate :active="loading" />

    <v-row dense no-gutters>
      <v-col
        cols="12" sm="12" md="12" lg="4"
        xl="4" class="pa-3 ma-0 mb-2 mr-1"
      >
        <v-img contain max-height="190" src="@/assets/logo_branca.png" />
      </v-col>

      <v-col
        colss="12" sm="12" md="12" lg="7"
        xl="7" class="pa-0 ma-0"
      >
        <v-card class="elevation-1" v-if="estipulante != null">

          <contratante-pj v-if="type == 'pj'" :params="paramsObj" />
          <contratante-pj v-if="type == 'pjvinc'" :params="paramsObj" />

          <contratante-pf v-if="type == 'pf'" :params="paramsObj" />
          <contratante-pf v-if="type == 'pfvinc'" :params="paramsObj" />

        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import ContratantePj from '@/components/contratante/site/ContratantePj';
import ContratantePf from '@/components/contratante/site/ContratantePf';

export default {
  components: {
    ContratantePj,
    ContratantePf,
  },
  props: {
    from: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    paramsObj: null,
    estipulante: null,
  }),
  created() {
    this.getEstipulante();
  },
  methods: {
    getEstipulante() {
      this.loading = true;
      this.$store
        .dispatch('estipulante/oneSite', { type: this.type, from: this.from })
        .then((estipulante) => {
          this.paramsObj = {
            type: this.type,
            e: estipulante.id,
            u: estipulante.updatedBy,
          };
          this.estipulante = estipulante;
        })
        .finally(async () => {
          this.loading = false;
        });
    },
  },
};
</script>
